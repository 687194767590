<template>
  <div :class="$style.page">
    <div :class="$style.container">
      <div :class="$style.infoWrapper">
        <span>Название: </span>
        <span :class="$style.info">{{ archiveBonusProgram.name }}</span>
      </div>
      <div :class="$style.infoWrapper">
        <span>Описание:</span>
        <div :class="$style.info">
          {{ archiveBonusProgram.description }}
        </div>
      </div>
      <div :class="$style.infoWrapper">
        <span>Дата начала:</span>
        <span :class="$style.info">{{
          formatDate(archiveBonusProgram.startsAt)
        }}</span>
      </div>
      <div :class="$style.infoWrapper">
        <span>Дата окончания:</span>
        <span :class="$style.info">
          {{ formatDate(archiveBonusProgram.endsAt) }}</span
        >
      </div>
      <div :class="$style.brandsWrapper">
        <span>Бренды участники:</span>
        <div :class="$style.cards">
          <BrandCard
            v-for="brand of archiveBonusProgram?.bonusProgramBrands?.data"
            :key="brand.id"
            :name="brand.name"
            :image="brand.image"
          />
        </div>
      </div>
      <span :class="$style.infoText">Информация по уровням</span>
      <div
        v-for="(level, index) of archiveBonusProgram?.bonusProgramLevels"
        :key="index"
        :class="$style.levelsWrapper"
      >
        <label :class="$style.levelLabel"
          >{{ getLevelLabel(level.name) }}
        </label>
        <div :class="$style.achieveAmountWrapper">
          <span>До следующего уровня:</span>
          <span :class="$style.levelInfo">{{
            archiveBonusProgram.bonusProgramLevels[index].achieveAmount
          }}</span>
        </div>

        <div :class="$style.cashbackWrapper">
          <span>Размер кешбэка %:</span>
          <span :class="$style.levelInfo">{{
            archiveBonusProgram.bonusProgramLevels[index].cashbackSize
          }}</span>
        </div>
      </div>
      <div :class="$style.statisticsWrapper">
        <span :class="$style.statisticsText">Статистика</span>
        <el-table
          v-if="archiveBonusProgram.bonusProgramDealers?.length"
          :data="archiveBonusProgram.bonusProgramDealers"
          max-height="500"
        >
          <el-table-column prop="dealerName" label="Название компании" />
          <el-table-column
            prop="transactionSum"
            label="Сумма покупок"
            sortable
          />
          <el-table-column prop="value" label="Кешбек" sortable />
          <el-table-column prop="level" label="Уровень" sortable>
            <template slot-scope="scope">
              {{ getLevelLabel(scope.row?.level) }}
            </template>
          </el-table-column>
        </el-table>
        <span v-else :class="$style.notStatisticsText"
          >К сожалению, в данной бонусной программе не участвовало ни одного
          дилера
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'

import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import {
  BONUS_PROGRAM_LEVELS,
  BONUS_PROGRAM_PLACES,
} from '@/constants/bonusProgram'
import { formatDate } from '@/helpers/index.js'

import BrandCard from '@/components/atoms/cards/BrandCard.vue'
export default {
  BONUS_PROGRAM_LEVELS,
  ADDSELLERS_ADMIN_ROUTES,
  BONUS_PROGRAM_PLACES,
  components: { BrandCard },
  data() {
    return {
      archiveBonusProgram: {},
    }
  },
  async created() {
    await this.getArchiveBonusProgramStatistic()
  },
  methods: {
    async getArchiveBonusProgramStatistic() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.BonusProgramActions.getArchiveStatistic(
          this.$route.params.id,
        )

      loading.close()

      if (error) return

      this.archiveBonusProgram = value
    },
    getLevelLabel(level) {
      return BONUS_PROGRAM_LEVELS[level] ?? ''
    },
    formatDate(date) {
      return formatDate(date)
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem 1rem 2rem 1rem;

  .container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .infoWrapper {
      display: flex;
      align-items: center;
      gap: 3rem;

      & > span:first-child {
        min-width: 9rem;
        white-space: nowrap;
      }

      .info {
        @include H200;
        max-width: 30rem;
      }
    }

    .brandsWrapper {
      display: flex;
      gap: 3rem;

      & > span:first-child {
        min-width: 9rem;
        white-space: nowrap;
      }

      .cards {
        display: flex;
        gap: 2.5rem;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .levelsWrapper {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-top: 1rem;

      .levelLabel {
        min-width: 12rem;
      }
    }

    .achieveAmountWrapper,
    .cashbackWrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .levelInfo {
        @include H200;
      }
    }

    .statisticsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      .statisticsText,
      .notStatisticsText {
        @include title;
        color: $black;
      }
    }

    .infoText {
      @include H100;
    }
  }
}
</style>
