<template>
  <div :class="$style.card">
    <div :class="$style.image">
      <img
        :src="setupImageData(image).original"
        alt="brand-card-image"
        @error="setDefaultImage"
        :class="$style.img"
      />
    </div>
    <span :class="$style.name">{{ name }}</span>
  </div>
</template>
<script>
import images from '@/mixins/images.js'

export default {
  mixins: [images],
  props: {
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" module>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .image {
    width: 8rem;
    height: 8rem;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
